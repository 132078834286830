import React from 'react'
import '../../css/Our_story.css'
import Team_img from '../../assets/about_us/professional_VFX _artists.jpeg'

const Our_story = () => {
  return (
    <div className='Our_story-container'>
        <div className='Our_story'>
        <div className='left'>
        <div className='head'>
            <h1>Our Story</h1>
            <p>At PIXL Visual Effects, we are a boutique FX studio with a highly skilled and efficient team of artists. Specializing in Houdini, our FX artists excel at creating and solving the most complex effects with precision and creativity.</p>
        </div>
            <div  className='img'>
                <img src={Team_img} alt=''/>
                <div className='layer'></div>
                <div className='layer'></div>
            </div>
        </div>
        <div className='right'>
        <div className='head'>
            <h1>Our Story</h1>
        </div>
        <div className='content'>
            <p>At<span> PIXL Visual Effects</span>, we are a boutique <span>FX studio</span> with a highly skilled and efficient team of artists. Specializing in Houdini, our FX artists excel at creating and solving the most complex effects with precision and creativity.</p>
               
            <p>Our passion lies in crafting stunning visuals that captivate and inspire. By embracing a process-oriented workflow, we ensure every project meets the highest standards of quality while adhering to tight deadlines.  </p>
            <p>
            Expanding beyond FX, <span>PIXL </span>is now making its mark in<span> gaming</span> and <span>VFX </span>production. We combine cutting-edge technology with artistic excellence to deliver immersive experiences that stand out.  
            </p>

            <p>

With every project, we strive for innovation, creativity, and perfection—bringing your vision to life in ways you’ve never imagined.
            </p>
        </div>
        </div>
        
      
        
        </div>
    </div>
  )
}

export default Our_story