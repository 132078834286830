import React from "react";
import "../../css/Journey_of_karthik.css";
import author_Img from '../../assets/logo/pixl_raghu.png'
 
const Journey_of_Raghunathan = () => {
  return (
    <div className="Journey-of-karthik-container">
      <div className="Journey-of-karthik">
        <div className="right">
            <div className="img">
                <img src={author_Img} alt="pixlvfx_author" />
            </div>
        </div>
        <div className="left">
          <div className="head">
            <h3 className="Journey_of_Raghunathan_heading">Raghunathan - Operations Manager (India & UK)</h3>
            {/* <h2>A Visionary FX Supervisor</h2> */}
          </div>
          <div className="content">
            <p>
            With extensive experience in administration and finance, Raghunathan plays a pivotal role in overseeing our operations across India and the UK. His strategic approach and dedication ensure seamless coordination, efficient resource management, and financial stability across all ventures. Raghunathan's leadership and expertise contribute significantly to the organization's growth and operational excellence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey_of_Raghunathan;
