import React, { useEffect, useState } from 'react'
import Nav from '../Componets/Nav'
import Pixl_Visual from '../Componets/Aboutus/Pixl_Visual'
import Our_story from '../Componets/Aboutus/Our_story'
import { Helmet } from 'react-helmet';
import Footer from '../Componets/Footer';
import What_Sets_Us_Apart from '../Componets/Aboutus/What_Sets_Us_Apart';
import Beyond_The_Expected from '../Componets/Aboutus/Beyond_The_Expected';
import Areas_Of_Expertise from '../Componets/Aboutus/Areas_Of_Expertise';
import Gaming_Real_Time from '../Componets/Aboutus/Gaming_Real_Time';
import Pixl_Philosophy from '../Componets/Aboutus/Pixl_Philosophy';
import Our_Tools from '../Componets/Aboutus/Our_Tools';

const Aboutus = () => {
   
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState('');

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription("PIXL VISUAL EFFECTS is a leading visual effects studio specializing in film VFX, real-time simulations, and immersive VR experiences. Discover high-quality VFX services for movies, games, and interactive projects.");
  }, []); // This effect ru
  return (
   <>
      {/* ---------------- SEO ------------------ */}
      <Helmet>
        
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
   <Nav/>
   <Pixl_Visual/>
   <Our_story/>
   <What_Sets_Us_Apart/>
   <Beyond_The_Expected/>
   <Areas_Of_Expertise/>
   <Gaming_Real_Time/>
   <Pixl_Philosophy/>
   <Our_Tools/>
   <Footer/>
   </>
  )
}

export default Aboutus