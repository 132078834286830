import Aboutus from "./pages/Aboutus";
import ContactUs from "./pages/ContactUs";
import Error from "./pages/Error";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WorkInProgress from "./pages/WorkInProgress";
import Gaming from "./pages/Gaming";
import Showreel from "./pages/Showreel";
import About_Founder from "./pages/About_Founder";
import Internship from './pages/Internship'
import Production_Training_page from "./pages/Production_Training_page";
import Innavation from "./pages/Innavation";
import Jobs from './pages/Jobs'
import Charity from './pages/Charity'
import Intership_register from "./Componets/register_form/Intership_register";
import Policy from "./pages/Policy";
import Termsandcondition from "./Componets/termsandprivacy/Termsandcondition";
import Termscodition from "./pages/Termscodition";
import Jobregister from "./Componets/job/Jobregister";

function App() {
  return (
   <>
   <Router>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/contact-us" element={<ContactUs/>}></Route>
      <Route path="/about-us" element={<Aboutus/>}></Route>
      <Route path="/:id" element={<Error/>}></Route>
      {/* Process */}
      <Route path="/from-the-founder" element={<WorkInProgress/>}></Route>
      <Route path="/innovation" element={<WorkInProgress/>}></Route>
      <Route path="/gaming" element={<Gaming/>}></Route>
      <Route path="/show-reel" element={<Showreel/>}></Route>
      <Route path="/about-founder" element={<About_Founder/>}></Route>
      <Route path="/internship" element={<Internship/>}></Route>
      <Route path="/training" element={<Production_Training_page/>}></Route>
      <Route path="/innavation" element={<Innavation/>}></Route>
      <Route path="/jobs" element={<Jobs/>}></Route>
      <Route path="/innovations" element={<Innavation/>}></Route>
      <Route path="/charity" element={<Charity/>}></Route>
      <Route path="/intership/intership-register" element={<Intership_register/>}></Route>
      <Route path="/terms-and-conditions" element={<Termscodition/>}></Route>
      <Route path="/privacy-and-policy" element={<Policy/>}></Route>
      <Route path="/Job-Register-pixlVFX" element={<Jobregister/>}></Route>
    </Routes>
   </Router>
   </>
  );
}

export default App;
