import React from 'react';
import '../../css/readytojoin.css';
import mail from '../../assets/production_Training/mail.png'

function Readytojoin() {
  return (
    <>
    <div className='readytojoin'>
      <h2>Ready to Join?</h2>
      <p>Take the first step toward advancing your career!</p>
      <h4>
       <img 
       src={mail}
       alt='mail'
       />
        connect@pixlvfx.com
      </h4>
      </div>
    </>
  );
}

export default Readytojoin;
