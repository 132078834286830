import React from "react";
import '../../css/Gaming_Real_Time.css'

const Gaming_Real_Time = () => {
  return (
    <div className="Gaming_Real_Time_container">
      <div className="Gaming_Real_Time">
        <div className="contant">
            {/* Gaming & Real-Time*/}
          <h1>2) Gaming & Real-Time</h1>
          <ul>
            <li>
              We're pushing the boundaries of what's possible in <strong>real-time
              environments</strong>
            </li>
            <li>Performance-optimized particle systems</li>
            <li><strong>Dynamic fluid simulations</strong> that run at gaming speeds</li>
            <li><strong>Physics-based destruction</strong> that reacts to player choices</li>
          </ul>
        </div>
        {/* Immersive Experiences */}
        <div className="contant">
          <h1>3) Immersive Experiences</h1>
          <ul>
            <li>
            <strong>Virtual reality</strong> demands a different kind of perfection. <strong>Our VR-optimized effects create</strong> presence without compromising quality
            </li>
            <li><strong>Responsive environmental effects</strong></li>
            <li>Spatially-aware particle systems</li>
            <li>Performance-conscious simulations that maintain immersion</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Gaming_Real_Time;
