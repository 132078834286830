import React from 'react'
import '../../css/Our_Tools.css'

const Our_Tools = () => {
  return (
    <div className='Our-Tools-container'>
            <div className='Our-Tools'>
                <div className='head'>
                    <h1>Our Tools</h1>
                    <p>While we're tool-agnostic, we've developed deep expertise with <strong>industry-leading software</strong></p>
                    <ul>
                        <li>Houdini for complex <strong>FX simulations</strong></li>
                        <li>Custom solvers for specialized effects</li>
                        <li>Proprietary tools for optimization and iteration</li>
                        <li><strong>Real-time engines</strong> for <strong>gaming and VR</strong></li>
                    </ul>
                </div>
            </div>
    </div>
  )
}

export default Our_Tools