import React from "react";
import "../../css/Looking_Forward.css";

const Looking_Forward = () => {
  return (
    <div className="Looking-Forward-container">
      <div className="Looking-Forward">
        <div className="left">
          <h1>Looking Forward </h1>
          <h2>The Future of PIXL VISUAL EFFECTS</h2>
        </div>
        <div className="right">
          <p>
            As Kartik looks to the future, his focus remains on pioneering new
            techniques and expanding the scope of visual effects in the film and
            gaming industries. He is dedicated to exploring emerging
            technologies and integrating them into PIXL’s offerings, ensuring
            the company remains at the cutting edge of the VFX industry.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Looking_Forward;
