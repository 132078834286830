import React from 'react'
import '../../css/charitycenter.css'

function Charitycenter() {
  return (
  <>
  <div className='charity-center'>
    <div className='charity-center-text'>
        <p>
        Your small contributions can make a HUGE difference! Help us bring hope and opportunities to these incredible kids. Let’s make their dreams come true— <span>one step at a time!</span> ❤️ 
        </p>
        <p>
        #SupportEducation #MFDIndia #PIXLForChange <br />
         #EmpoweringDreams #DeafAndDumbSupport #OrphanCare #TogetherWeCan 
        </p>
        <p>
        https://www.mfdindia.org/
        </p>
    </div>
  </div>
  
  
  </>
  )
}

export default Charitycenter