import React from 'react'
import imageman from '../../assets/internship/internship-man.png'
import '../../css/internshipman.css'

function Internshipman() {
  return (
    <>
    <div className='internship-man-container'>
<div className='man-container'>
    <div className='man-container-left-text'>
<h1>
Why It’s Unpaid
</h1>
<ul>
    <li>This internship focuses on <span>learning and experimentation </span>, not commercial projects.</li>
    <li>You’ll work with<span> Houdini </span> and <span>Unreal</span> in a creative, non-commercial environment, exploring cutting-edge techniques and production workflows. </li>
</ul>
<h1>
Who Should Apply
</h1>
<ul>
    <li>
    Individuals passionate about  <span>learning the industry’s approach </span>and gaining insights into real-world production.
    </li>
    <li>
    Those looking to connect with industry professionals and understand future career paths in VFX.
    </li>
    <li>
    Enthusiastic learners ready to invest in themselves and committed to personal growth.
    </li>
</ul>

    </div>
    <div className='man-container-right-image'>
        <div className='right-man-image'>
            <img 
            src={imageman}
            alt='learning-industry-approch-person'
            />
        </div>
    </div>
</div>
    </div>
    
    </>
  )
}

export default Internshipman