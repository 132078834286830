import React, { useState } from "react";
import "../../css/Connect_Collaborate.css";
import Vfx_Video from "../../assets/pixl_videos/pixl_slider_high.mp4";
import Gaming from "../../assets/pixl_videos/karala_warriors_teaser_pixl_game.mp4";
import play_Btn from "../../assets/icon/play_btn.png"

const Connect_Collaborate = () => {
  const [previewSrc, setPreviewSrc] = useState(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const handleVideoControll = (src) => {
    const preview_1 = Vfx_Video;
    const preview_2 = Gaming;

    // Set the video source based on which video was clicked
    if (src === 1) {
      setPreviewSrc(preview_1);
    } else {
      setPreviewSrc(preview_2);
    }

    // Open the preview modal
    setIsOpenPreview(true);
  };

  return (
    <div className="Connect-Collaborate-container">
      <div className="Connect-Collaborate">
        <div className="heading">
          <h1>Connect & Collaborate</h1>
          <p>Are you ready to take your project to the next level?</p>
          <p>
            Connect with Kartik G and the team at PIXL VISUAL EFFECTS to explore
            how we can bring your creative vision to life with stunning visual
            effects and immersive experiences.
          </p>
        </div>
        <div className="portpolio_highlight">
          <h1>Portfolio Highlights</h1>
          <p>Dive into Kartik’s impressive work through his showreels</p>
          <div className="video-container">
            <div className="video">
              <div className="items">
                <div className="img">
                  <video muted autoPlay loop>
                    <source src={Vfx_Video}></source>
                  </video>
                  <div className="play-btn" onClick={() => handleVideoControll(1)}>
                    <img
                      src={play_Btn}
                      alt="Responsive environmental effects for VR games"
                    />
                  </div>
                </div>
              </div>

              <div className="items">
                <div className="img">
                  <video muted autoPlay loop>
                    <source src={Gaming}></source>
                  </video>
                  <div className="play-btn" onClick={() => handleVideoControll(2)}>
                    <img
                      src={play_Btn}
                      alt="Responsive environmental effects for VR games"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Checkbox to control the modal visibility */}
      <input
        type="checkbox"
        id="handlePreviewVideo"
        checked={isOpenPreview}
        onChange={() => setIsOpenPreview(!isOpenPreview)} // Toggle modal visibility
      />

      {/* Preview Section */}
      {isOpenPreview && (
        <div className="preview-container open">
          <div className="close-btn" onClick={() => setIsOpenPreview(false)}>
            Close
          </div>
          <div className="preview">
            {/* Ensure the previewSrc is set */}
            <video controls autoPlay>
              <source src={previewSrc} />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default Connect_Collaborate;
