import React from 'react'
import '../../css/Lets_Talk_efforts.css'

const Lets_Talk_efforts = () => {
  return (
    <div className='lets_talk_efforts_container'>
    <div className='lets_talk_efforts'>
    <div className='head'>
    <h1>Let's Talk Effects</h1>
    <h3>Whether you need:</h3>
    <ul>
        <li>Complex fluid simulations</li>
        <li>Cutting-edge particle effects</li>
        <li>Real-time VFX solutions</li>
        <li>Full-scale VFX supervision</li>
    </ul>
    <h3>We're here to help bring your vision to life.</h3>
    </div>
    <div className='content'>
    <h1>Every great effect starts with a conversation. <span>Let's have one!</span></h1>    
    <h3>Ready to start? Drop us a line at <span>business@pixlvfx.com</span></h3>
    </div>      
    </div>
    </div>
  )
}

export default Lets_Talk_efforts