import React from 'react'
import '../../css/privacy.css'

function Privacyandpolicy() {
  return (
  <>
  <div className='privacy-policy-container'>

    <div className='privacy-policy'>
        <div className='intro'>
        <h1>
        Privacy Policy for Pixl VFX
        </h1>
        <h2>
        Introduction
        </h2>
        <p>
        At  <span>Pixl VFX </span>, we are committed to protecting the privacy of our clients, visitors, and users . This Privacy Policy outlines how we collect, use, store, and protect your personal information when you visit our website or use our services.   
        </p>
        <p>
        By using our website or services, you consent to the collection and use of your information as described in this Privacy Policy.
        </p>
        </div>


<div className='list-content'>
    <h2>
    1. Information We Collect
    </h2>
    <ul>
        <li>
      <span>  Personal Information:</span> Name, email address, phone number, billing address, etc.
        </li>
<li>
<span>Usage Data: </span>Information about how you use our website, services, and any interactions with our website (e.g., IP addresses, browser type, pages visited).
</li>

    </ul>
</div>



<div className='list-content'>
    <h2>
    2. How We Use Your Information
    </h2>
    <p>
    We may use the information we collect for the following purposes:
    </p>
    <ul>
        <li>
        To provide, maintain, and improve our services
        </li>
<li>
    To communicate with you, including sending project updates or promotional material
</li>
<li>
To process payments for services rendered
</li>
<li>
To monitor and analyze the use of our website and services
</li>
<li>
    To comply with legal obligations
</li>

    </ul>
</div>




<div className='list-content'>
    <h2>
    3. Data Sharing and Disclosure
        </h2>
    <ul>
        <li>
        <span>Service Providers:</span> We may share information with third-party companies that help us with operations (e.g., payment processors, email services, or cloud hosting).
        </li>
<li>
<span>Legal Compliance: </span>If required by law, we may disclose your information to law enforcement or other government authorities.
</li>

    </ul>
</div>


<div className='list-content'>
    <h2>
    4. Data Security
    </h2>
    <p>
    We take reasonable precautions to protect your personal information. However, no method of internet transmission or electronic storage is 100% secure. We cannot guarantee the absolute security of your data.
    </p>
</div>


<div className='list-content'>
    <h2>
    5. Your Rights
    </h2>
    <p>
    You have the right to:
    </p>
    <ul>
        <li>
        Access the personal information we hold about you
        </li>
        <li>
        Correct any inaccuracies in your personal information
        </li>
        <li>
        Request that we delete your personal information (subject to certain exceptions)
        </li>
        <li>
        Withdraw consent for the use of your data at any time
        </li>
    </ul>
</div>





<div className='list-content'>
    <h2>
    6. Children’s Privacy
    </h2>
    <p>
    Our services are not intended for children under 13 years old. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected information from a child, we will take steps to delete that data.
    </p>
</div>


<div className='list-content'>
    <h2>
    7. Changes to This Privacy Policy
    </h2>
    <p>
    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. Please review this policy periodically.
    </p>
</div>


<div className='list-content'>
    <h2>
    8. Contact Us
    </h2>
<p>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
</p>
    <ul>
        <li>
       <span> Email: </span> business@pixlvfx.com
        </li>
        <li>
        <span>Phone:</span> +91  413 296 1868
        </li>
        <li>
        <span>Address:</span>
        PIXL VISUAL EFFECTS, <br />
No. 30, Second Floor, <br />
Subbaiah Salai, <br />
Pondicherry 605002, <br />
India.

        </li>
    </ul>
</div>
    </div>
  </div>
  
  
  
  
  
  
  </>
  )
}

export default Privacyandpolicy