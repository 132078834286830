import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react'
import Nav from '../Componets/Nav'
import Footer from '../Componets/Footer'
import Merging_Creativity from '../Componets/Innavation/Merging_Creativity'
import TechInnovation from '../Componets/Innavation/TechInnovation'
import Innovation_feature from '../Componets/Innavation/Innovation_feature'
import Our_Vision from '../Componets/Innavation/Our_Vision'
import Get_in_Touch from '../Componets/Innavation/Get_in_Touch';

const Innavation = () => {
    // Store the meta description in state
    const [metaDescription, setMetaDescription] = useState("");

    // You can dynamically update the meta description if needed
    useEffect(() => {
      // You could call an API or some logic to update the description here
      setMetaDescription(
        "Kartik G - Visual Effects Lead with 15+ years of experience in FX, Liquid Simulations, and Pyro Techniques. Creator of stunning effects for Netflix, HBO, and major films. Discover the portfolio of an industry-leading VFX artist."
      );
    }, []); // This effect ru
  return (
    <>
      {/* ---------------- SEO ------------------ */}
      <Helmet>
        
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
    <Nav/>
   <Merging_Creativity/>
   <TechInnovation />
   <Innovation_feature/>
   <Get_in_Touch/>
   <Our_Vision/>
    <Footer/>
    </>
  )
}

export default Innavation