import React from "react";
import "../../css/Address.css";
// Img
import Email_Img from "../../assets/icon/email.png";
import Location_Img from "../../assets/icon/location.png";
const Address = () => {
  return (
    <div className="Address-container">
      <div className="Address">
        <div className="head">
          <h1>
            Located in the heart of Pondicherry, where French colonial charm
            meets cutting-edge technology.
          </h1>
        </div>
        <div className="way-to-connect">
          <h2>Ways to Connect</h2>
          <div className="items">
            {/* left */}
            <div className="left">
              <div className="list-head">
                <img src={Location_Img} alt="location" />
                <h1>Visit Our Studio</h1>
              </div>
              <div className="location">
                <p>
                  PIXL VISUAL EFFECTS,
                  <br />
                  No. 30, Second Floor,
                  <br />
                  Subbaiah Salai,
                  <br />
                  Pondicherry 605002,
                  <br />
                  India.
                </p>
              </div>
            </div>
            {/* right */}
            <div className="right">
              <div className="list-head">
                <img src={Email_Img} alt="email" />
                <h1>Start a Conversation</h1>
              </div>
              <div className="contact-info">
                <p>
                  Email: <span>business@pixlvfx.com</span>
                </p>
                <p>
                  Phone: <span>+91 413 296 1868</span>
                </p>
                <p>
                  Website: <span> www.pixlvfx.com</span>
                </p>
              </div>
            </div>
            <div className="google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.7149049867903!2d79.82796671092778!3d11.924918036911484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5361876b8f79bf%3A0x45821583613f78cd!2sPIXL%20VFX!5e0!3m2!1sen!2sin!4v1731930123376!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
