import React from 'react'
import "../../css/Vfx_Struggle.css";

import video_Struggle from '../../assets/home/Vfx-Struggle.mp4'

const Vfx_Struggle = () => {
  return (
    <div className='Vfx-Struggle-container'>
        <div className='Vfx-Struggle'>
        
        <div className='right'>
            <video muted autoPlay loop>
                <source src={video_Struggle}>
                </source>
            </video>
        </div>
        <div className='left'>
            <div className='head'>
                <h1>The VFX Struggle Is Real</h1>
            </div>
            <div className='content'>
                <p><strong>Let's be honest:</strong> bringing imagination to life isn’t easy, especially in the high-pressure world of visual effects. You’re juggling</p>
                <p>Directors wanting <strong>"Avatar-level effects"</strong> on a fraction of the budget.</p>
                <p>Producers asking, <strong>"Can’t we just use stock CGI effects?".</strong></p>
                <p>Technical artists drowning in simulation times and pipeline issues.</p>
                <p>Constant pressure to push the boundaries of <strong>Houdini effects</strong> while meeting impossible deadlines.</p>
            </div>
        </div>
        </div>
        
    </div>
  )
}

export default Vfx_Struggle