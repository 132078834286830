import React from "react";
import '../../css/Pixl_Philosophy.css'

const Pixl_Philosophy = () => {
  return (
    <div className="Pixl-Philosophy-container">
      <div className="Pixl-Philosophy">
        <div className="head">
          <h1>The PIXL Philosophy</h1>
        </div>
        <div className="content">
          <p>
            We believe that the <strong>best effects are the ones</strong> you don't notice. Not
            because they're subtle (though sometimes they are), but because
            they're so convincing that your <strong>brain accepts them as real.</strong> This
            philosophy guides everything we do.
          </p>
          <ul>
            <li><strong>Study Reality :</strong> Nature is our first teacher</li>
            <li><strong>Push Boundaries :</strong> "Good enough" isn't in our vocabulary</li>
            <li><strong>Serve the Story :</strong> Every effect exists to support the narrative</li>
            <li><strong> Optimize Relentlessly :</strong> Efficiency and quality aren't mutually exclusive</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pixl_Philosophy;
