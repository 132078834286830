import React, { useState, useEffect } from 'react';
import Nav from '../Componets/Nav'
import Footer from '../Componets/Footer';
import Slider from '../Componets/Home/Slider'
import { Helmet } from 'react-helmet';
import ImageComparisonWithSlider from '../Componets/ImageComparisonWithSlider';
import Vfx_Struggle from '../Componets/Home/Vfx_Struggle';
import What_If_you_could from '../Componets/Home/What_If_you_could';
import Where_Pixl_Visualeffect from '../Componets/Home/Where_Pixl_Visualeffect';
import Talk_With_Us from '../Componets/Home/Talk_With_Us';
import Lets_Get_Real from '../Componets/Home/Lets_Get_Real';
import Impossible_Possible from '../Componets/Home/Impossible_Possible';
import TrustedOurClient from '../Componets/Home/TrustedOurClient';




const Home = () => {
  
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState("");

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription("PIXL VISUAL EFFECTS is a leading VFX studio based in Pondicherry, India, specializing in high-end FX simulations, Houdini effects, and advanced CGI for film, gaming, and immersive experiences. Our team transforms complex challenges into breathtaking digital entertainment.");
  }, []); // This effect runs once when the component is mounted


  return (
    <>
      
    <Nav/>
    <Slider/>
    <Vfx_Struggle/>
   
    <Where_Pixl_Visualeffect/>
    <What_If_you_could/>
    <Talk_With_Us/>
    <Impossible_Possible/>
    <Lets_Get_Real/>
  
 
   <Footer/>
    </>
  )
}

export default Home