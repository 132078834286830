import React, { useState } from "react";
import "../../css/Inquire_Now.css";

const Inquire_Now = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    projectDetails: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    projectDetails: "",
    message: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(""); // Track success or error message

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });

    validateField(id, value);
  };

  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case "firstName":
      case "lastName":
        if (!value.trim()) {
          error = `${field.replace(/([A-Z])/g, " $1")} is required`;
        } else if (/[^a-zA-Z]/.test(value)) {
          error = `${field.replace(
            /([A-Z])/g,
            " $1"
          )} must only contain letters`;
        }
        break;

      case "companyName":
      case "projectDetails":
        if (!value.trim()) {
          error = `${field.replace(/([A-Z])/g, " $1")} is required`;
        } else if (/[^a-zA-Z0-9 ]/.test(value)) {
          error = `${field.replace(
            /([A-Z])/g,
            " $1"
          )} cannot contain special characters`;
        }
        break;

      case "email":
        if (!value.trim()) {
          error = "Email Address is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Please enter a valid email address";
        }
        break;

      case "phone":
        if (!value.trim()) {
          error = "Phone Number is required";
        } else if (!/^\d{10}$/.test(value)) {
          error = "Phone number should be 10 digits";
        }
        break;

      case "message":
        if (!value.trim()) {
          error = "Message is required";
        } else if (value.length > 500) {
          error = "Message cannot exceed 500 characters!";
        }
        break;

      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmissionStatus(""); // Clear any previous success/error messages
    setIsLoading(true); // Start loading

    const isValid =
      Object.values(errors).every((err) => !err) &&
      Object.values(formData).every((value) => value.trim() !== "");

    if (!isValid) {
      setSubmissionStatus("Please correct the errors before submitting.");
      setIsLoading(false); // Stop loading
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch("https://pixlvfx.com/pixlbackend/contactusmail.php", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        alert("Your inquiry has been submitted!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          companyName: "",
          projectDetails: "",
          message: "",
        });
        setErrors({});
      } else {
        setSubmissionStatus("There was an error submitting your inquiry.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("There was an error submitting your inquiry.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="Inquire-Now-container">
      <div className="Inquire-Now">
        <form onSubmit={handleSubmit}>
          <div className="head">
            <h1>Inquire Now</h1>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
            </div>
            <div className="field">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && (
                <div className="error">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="email">Email Address*</label>
              <input
                type="email"
                id="email"
                placeholder="Enter Your Email Address"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="phone">Phone Number*</label>
              <input
                type="number"
                id="phone"
                placeholder="Enter Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="companyName">Company Name*</label>
              <input
                type="text"
                id="companyName"
                placeholder="Enter the Company Name"
                value={formData.companyName}
                onChange={handleChange}
              />
              {errors.companyName && (
                <div className="error">{errors.companyName}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="projectDetails">Project Details*</label>
              <input
                type="text"
                id="projectDetails"
                placeholder="Enter your Project Details"
                value={formData.projectDetails}
                onChange={handleChange}
              />
              {errors.projectDetails && (
                <div className="error">{errors.projectDetails}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="message">Message*</label>
              <textarea
                id="message"
                placeholder="Enter your Message"
                value={formData.message}
                onChange={handleChange}
                maxLength={500}
              />
              {errors.message && <div className="error">{errors.message}</div>}
            </div>
          </div>
          {isLoading ? (
            <div className="row">
              <button type="button" disabled>
                <div className="loading"></div>
              </button>
            </div>
          ) : (
            <div className="row">
              <button type="submit">Submit</button>
            </div>
          )}
          {submissionStatus && !isLoading && (
            <div className="submission-status">{submissionStatus}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Inquire_Now;
