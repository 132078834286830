import React from 'react'
import '../../css/charitylanding.css'
import image1 from '../../assets/charity/charity_1.png'
import { useNavigate } from 'react-router-dom';

function Charitylanding() {
    const navigate = useNavigate();
  return (
    <>
    <div className='charity-landing-container'>
    <div className='charity-landing'>

<div className='charity-landing-left'>
    <div className='charity-landing-text'>
        <h1>
        PIXL Supports MFD India
        </h1>
        <p>
        Empowering Dreams for <span>Deaf</span>, <span>Dumb</span>, and <span>Orphaned Children</span>
        </p>
        <br />
        <p>
        At PIXL Visual Effects, we proudly stand with<span> MFD India </span> in their mission to provide education, support, and opportunities for deaf, dumb, and orphaned children. Together,<span> we are creating a brighter future where every child has the chance to dream big and achieve their goals. </span>

        </p>
    </div>

    <div className='charity-landing-icon-text'>
<ul>
    <li>
    Tharameshram MFD School
    </li>
    <li>
    +91 75882 54776 
    </li>

    <li>The Lillian School</li>
</ul>
{/* <button onClick={() => navigate('/contact-us')}>Donate</button> */}
    </div>
</div>
<div className='charity-right-image'>
    <div className='charity-image'>
        <img 
        src={image1}
        alt='pixl-vfx-charity team'
        />
    </div>
</div>


    </div>
    
    <div className='charity-landing-icon-text1'>
<ul>
    <li>
    Tharameshram MFD School
    </li>
    <li>
    +91 75882 54776 
    </li>

    <li>The Lillian School</li>
</ul>
<div className='donate-image'>
{/* <button onClick={() => navigate('/contact-us')}>Donate</button> */}
</div>
    </div>
    </div>
    
    
    
    </>
  )
}

export default Charitylanding