import React from 'react'
import Video_file from '../../assets/home/Lets_Get_Real.mp4'
import "../../css/Lets_Get_Real.css";

const Lets_Get_Real = () => {
  return (
    <div className='Lets-Get-Real-container'>
        <div className='Lets-Get-Real'>
            <div className='left'>
                <video  autoPlay loop muted>
                    <source src={Video_file}>
                    </source>
                </video>
            </div>
            <div className='right'>
                <div className='head'>
                    <h1>Let’s Get Real</h1>
                </div>
                <div className='text'>
                    <p>We know you’ve got options when it comes to <strong>choosing a special effects company.</strong> But if you’re tired of:</p>
                    <p>VFX that look <strong>"good enough"</strong> but not <strong>"great".</strong></p>
                    <p>Missing deadlines due to long render times in computer graphics.</p>
                    <p>Compromising your creative vision because of technical limitations.</p>
                    <p><strong>Endless back-and-forth</strong> revisions that waste time and resources.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Lets_Get_Real