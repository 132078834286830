import React from 'react'
import Visual_Effects_Studio from '../../assets/innovation/Visual_Effects_Studio.jpg'
import Real_Time_VFX from '../../assets/innovation/Real-Time_VFX.jpg'
import Immersive_VFX_Experiences from '../../assets/innovation/Immersive_VFX_Experiences.jpg'
import Dynamic_Fluid_Simulations from '../../assets/innovation/Dynamic_Fluid_Simulations.jpg'
import Structural_Destruction_Effects from '../../assets/innovation/Structural_Destruction_Effects.jpg'
import '../../css/Innovation_feature.css'

const Innovation_feature = () => {
  return (
    <div className='Innovation-feature-container'>
        <div className='Innovation-feature'>
            {/* Innovation feature list items */}
            <div className='items'>
                <div className='left'>
                    <div className='head'>
                        <strong>01</strong>
                        <h1>AR & VR Solutions</h1>
                    </div>
                    <div className='content'>
                        <p>We design augmented and virtual reality experiences that bring cultural heritage, education, and entertainment to life. From creating lifelike environments to interactive simulations, our AR/VR projects are tailored for museums, theme parks, and beyond.</p>
                    </div>
                </div>
                <div className='right'>
                    <div className='img'>
                        <img src={Visual_Effects_Studio} alt='Best visual effects studio for gaming projects'/>
                    </div>
                </div>
            </div>
            {/* Innovation feature list items */}
            <div className='items'>
                <div className='left'>
                    <div className='head'>
                        <strong>02</strong>
                        <h1>Game Development</h1>
                    </div>
                    <div className='content'>
                        <p>Leveraging our strengths in VFX and simulation, we develop immersive game prototypes and IPs. Our work combines stunning visuals with engaging gameplay, setting new standards in interactive entertainment.
                        </p>
                    </div>
                </div>
                <div className='right'>
                    <div className='img'>
                        <img src={Real_Time_VFX} alt='Performance-optimized fluid simulations for VR'/>
                    </div>
                </div>
            </div>
            {/* -------------------------- */}
              {/*Hologram Technology*/}
              <div className='items'>
                <div className='left'>
                    <div className='head'>
                        <strong>03</strong>
                        <h1>Hologram Technology</h1>
                    </div>
                    <div className='content'>
                        <p>Our holographic video solutions transform how people experience 3D visuals, adding a layer of depth and realism for exhibitions, advertising, and live events.
                        </p>
                    </div>
                </div>
                <div className='right'>
                    <div className='img'>
                        <img src={Immersive_VFX_Experiences} alt='How to create believable fire dynamics in VFX'/>
                    </div>
                </div>
            </div>
            {/* -------------------------- */}
              {/*Smart Road Systems*/}
              <div className='items'>
                <div className='left'>
                    <div className='head'>
                        <strong>04</strong>
                        <h1>Smart Road Systems</h1>
                    </div>
                    <div className='content'>
                        <p>PIXL is innovating advanced road safety solutions designed to enhance urban mobility. These systems integrate cutting-edge tech to improve road safety, traffic flow, and driver awareness.
                        </p>
                    </div>
                </div>
                <div className='right'>
                    <div className='img'>
                        <img src={Structural_Destruction_Effects} alt='VR-optimized particle effects studio'/>
                    </div>
                </div>
            </div>
            {/* -------------------------- */}
              {/*Yalifx and Beyond*/}
              <div className='items'>
                <div className='left'>
                    <div className='head'>
                        <strong>05</strong>
                        <h1>Yalifx and Beyond</h1>
                    </div>
                    <div className='content'>
                        <p>We're crafting Yalifx, our proprietary stock VFX product, along with SwirlZ and Adore-tools and systems that streamline the creative process while delivering unparalleled quality for creators worldwide.
                        </p>
                    </div>
                </div>
                <div className='right'>
                    <div className='img'>
                        <img src={Dynamic_Fluid_Simulations} alt='Million-particle fluid simulations for gaming'/>
                    </div>
                </div>
            </div>
            {/* -------------------------- */}
        </div>
    </div>
  )
}

export default Innovation_feature