import React from 'react'
import '../../css/Our_Vision.css'

const Our_Vision = () => {
  return (
    <div className='Our-Vision-container'>
        <div className='Our-Vision'>
            <div className='main-text'>
            <div className='head'>
                <h1>Our Vision</h1>
            </div>
            <div className='content'>
                <p>Under the leadership of Kartik Gurunathan, with over 15 years of experience in VFX, games, and tech-driven innovation, PIXL is committed to pushing creative and technological boundaries. Whether it's enhancing cinematic storytelling or developing real-world solutions, we strive to leave a meaningful impact across industries.</p>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Our_Vision