
import React, { useState } from "react";
import "../../css/kalara.css";
// icon imports for navigation
import right_arrow from "../../assets/icon/left_arrow.png";
import left_arrow from "../../assets/icon/right_arrow.png";
// gallery images
import img_1 from "../../assets/kalara/karala_1.jpg";
import img_2 from "../../assets/kalara/karala_2.jpg";
import img_3 from "../../assets/kalara/karala_3.jpg";
import img_4 from "../../assets/kalara/karala_4.jpg";
import img_5 from "../../assets/kalara/karala_5.jpg";
import img_6 from "../../assets/kalara/karala_6.jpg";
import img_7 from "../../assets/kalara/karala_7.jpg";
import img_8 from "../../assets/kalara/karala_8.jpg";
import img_9 from "../../assets/kalara/karala_9.jpg";
import img_10 from "../../assets/kalara/karala_10.jpg";
import img_11 from "../../assets/kalara/karala_11.jpg";
import img_12 from "../../assets/kalara/karala_12.jpg";
// import img_13 from "../../assets/kalara/karala_13.jpg";
import img_14 from "../../assets/kalara/karala_14.jpg";
import img_15 from "../../assets/kalara/karala_15.jpg";

const GalleryComponent = () => {
  const [selectedImageUrl, setSelectedImageUrl] = useState(""); // State to store selected image URL
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State to control preview modal visibility
  const images = [
    { src: img_1, alt: "" },
    { src: img_2, alt: "" },
    { src: img_3, alt: "" },
    { src: img_4, alt: "" },
    { src: img_5, alt: "" },
    { src: img_6, alt: "" },
    { src: img_7, alt: "" },
    { src: img_8, alt: "" },
    { src: img_9, alt: "" },

    { src: img_11, alt: "" },
    { src: img_12, alt: "" },
    { src: img_10, alt: "" },
    { src: img_14, alt: "" },
    { src: img_15, alt: "" },
  ];

  // Handle clicking an image in the gallery to open the preview
  const openImagePreview = (index) => {
    setSelectedImageUrl(images[index].src); // Set the preview image URL
    setIsPreviewOpen(true); // Open the preview modal
  };

  // Function to handle left and right navigation with animation
  const navigateImage = (direction) => {
    const currentIndex = images.findIndex((item) => item.src === selectedImageUrl);
    let newIndex = currentIndex;

    // Add animation class before updating the image
    const previewImg = document.getElementById('imagePreview');
    if (direction === "left") {
      previewImg.classList.add('left-slide');
      newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    } else if (direction === "right") {
      previewImg.classList.add('right-slide');
      newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    }

    // Wait for the animation to finish before removing the class and updating the image
    setTimeout(() => {
      previewImg.classList.remove('left-slide', 'right-slide'); // Remove animation class
      setSelectedImageUrl(images[newIndex].src); // Update the preview URL to the new image
    }, 400); // Adjust this timeout to match the length of your animation in CSS (300ms is a common duration)
  };

  return (
    <>
      <div className="gallery-container">
        <div className="gallery">
          <div className="header">
            <h1>Gallery</h1>
          </div>

          {/* Gallery of images */}
          <div className="image-gallery">
            {images.map((item, index) => (
              <img
                key={index}
                src={item.src}
                alt={item.alt || "Gallery Image"}
                onClick={() => openImagePreview(index)} // Open preview on click
              />
            ))}
          </div>
        </div>
      </div>

      {/* Image Preview Modal */}
      <input
        type="checkbox"
        id="previewModal"
        checked={isPreviewOpen}
        onChange={() => setIsPreviewOpen(!isPreviewOpen)} // Toggle the preview visibility
      />
      <div className="image-preview-container">
        <div className="overlay" onClick={() => setIsPreviewOpen(false)}></div>
        <div className="navigation-buttons">
          <div className="prev" onClick={() => navigateImage("left")}>
            <img src={left_arrow} alt="Left Arrow" />
          </div>
          <div className="next" onClick={() => navigateImage("right")}>
            <img src={right_arrow} alt="Right Arrow" />
          </div>
        </div>
        <div className="preview-image">
          <div className="image-wrapper">
            <div
              className="close-preview"
              onClick={() => setIsPreviewOpen(false)} // Close the preview
            >
              X
            </div>
            <img src={selectedImageUrl} alt="Preview" id="imagePreview" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;


