import React from 'react';
import '../../css/howitwork.css';
import img1 from '../../assets/production_Training/howit_1.png';
import img2 from '../../assets/production_Training/howit_2.png';
import img3 from '../../assets/production_Training/howit_3.png';

function Howitwork() {
  const steps = [
    {
      title: "1. Choose Your Course",
      description: "Select the program that aligns with your goals.",
      img: img1,
    },
    {
      title: "2. Inquire About Pricing",
      description: "Course fees start at affordable rates Rs 40,000 + GST depending on the program. Flexible payment plans are available.",
      img: img2,
    },
    {
      title: "3. Join the Program",
      description: "Receive high-quality training to get industry-ready.",
      img: img3,
    },
  ];

  return (
    <div className="container">
        <h1>
        How It Works?
        </h1>
      {steps.map((step, index) => (
        <div key={index} className="step">
          <img src={step.img} alt={step.title} />
          <div className="step-content">
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Howitwork;
