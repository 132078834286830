import React from 'react'
import '../../css/joblast.css';

function Joblast() {
  return (
<>

<div className='job-last-container'>
    <div className='job-last'>
        <h1>
        Why Work with Us?
        </h1>

<p>
    <span>
    Artist-Centric Culture: &nbsp;
    </span>
     Your creativity and vision take center stage here.
</p>

<p>
    <span>
    Work-Life Balance: &nbsp;
    </span>
  We value your personal time, offering limited work hours to ensure a healthy balance.
</p>




<p>
    <span>
    Comprehensive Benefits:  &nbsp;
    </span>
    Enjoy health insurance coverage and the security it brings.
</p>



<p>
    <span>
    Hybrid Work Model: &nbsp;
    </span>
    Flexibility to work remotely or in the studio as needed.
</p>





    </div>
</div>
</>
  )
}

export default Joblast