import React from "react";
import "../../css/Notable_Achievements.css";
const Notable_Achievements = () => {
  return (
    <div className="Notable-Achievements-container">
      <div className="Notable-Achievements">
        <div className="left">
          <div className="head">
            <h1>Notable Achievements</h1>
            <h2>A Legacy of Excellence</h2>
          </div>
      
        <div className="content">
          <p>
            Kartik’s career is adorned with numerous notable achievements. His
            work has been featured in high- profile films and critically
            acclaimed games, making him a go-to FX Supervisor for studios around
            the world.
          </p>
        </div>
        </div>
        
        <div className="right">
            <div className="content">
                <p>Some highlights of his achievements include:</p>
                <ul>
                    <li>Over 15 years of experience in the VFX and gaming industries</li>
                    <li>Expertise in high-end FX simulations using cutting-edge tools like Houdini</li>
                    <li>Contributions to blockbuster films and top-tier games, setting new industry standards</li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Notable_Achievements;
