import React, { useState } from "react";
import "../../css/Gaming.css";

const Gaming_video=() => {

  return (
    <>
      <div className="gaming-container">
        <div className="gaming">
       <h1>PUSHING PIXELS TO PERFECTION</h1>
       <p>Crafting <strong>next-gen gaming experiences</strong> with <strong>cutting-edge VFX</strong> and <strong>simulations</strong> From explosive particle systems to fluid dynamics, <strong>every frame tells a story</strong></p>
        </div>
      </div>
    </>
  );
};

export default Gaming_video;
