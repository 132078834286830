import React from 'react'
import '../../css/whychoose.css'

function Whychoose() {
  return (
    <>
    <div className='whychoose-container'>
    <h1>
            Why Choose PIXL VFX?
            </h1>
        <div className='whychoose'>
            
            <div className='whychoose-left-right'>
                <div className='whychoose-left'>
                    <ul>
                        <li>
                        Affordable, high-quality training tailored to the latest industry trends.
                        </li>
                        <li>
                        Industry-ready curriculum designed to bridge the gap between traditional methods and emerging VFX trends.
                        </li>
                    </ul>
                </div>


                <div className='whychoose-right'>
                    <ul>
                        <li>
                        Courses supervised by world-class industry professionals and experts, ensuring quality and relevance.
                        </li>
                        <li>
                        No need to go abroad – receive top-tier education without the need for travel to the UK, US, or Canada.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Whychoose