import React from 'react';
import '../../css/jobs.css';
import jobimage from '../../assets/job/job-image.png';
import { useNavigate } from 'react-router-dom';

function Joblanding() {
  const navigate = useNavigate();

  return (
    <div className="job-landing-container">
      <div className="joblanding">
        <div className="job-landing-left">
          <div className="job-left-image"></div>
        </div>
        <div className="job-right-text">
          <h1>
            <span>Join Our Team</span>
          </h1>
          <h1>At PIXL Visual Effects!</h1>
          <div className="job-text">
            <p>
              At PIXL, we're always on the lookout for exceptionally talented FX and game artists to join our
              creative family.
            </p>
            <p>
              If you're passionate about pushing boundaries and crafting breathtaking visuals, we'd love to hear from
              you.
            </p>
          </div>
          <p>
            Send your portfolio and resume to &nbsp;
            <strong>jobs@pixlvfx.com</strong> &nbsp; and take the next step in your career with us!
          </p>
          <button onClick={() => navigate('/Job-Register-pixlVFX')}>Apply Now</button>
        </div>
      </div>

      <div className="hidden-container">
        <div className="hidden-image">
          <img src={jobimage} alt="pixl_vfx_join" />
        </div>
        <button onClick={() => navigate('/Job-Register-pixlVFX')}>Apply Now</button>
      </div>
    </div>
  );
}

export default Joblanding;
