import React from 'react'
import "../../css/IMDP.css";
import IMDP_Img from '../../assets/icon/imdb.png'
const IMDP = () => {
  return (
    <div className='IMDP-container'>
        <div className='IMDP'>
            <p>Learn more about Kartik’s professional journey and contributions on</p>
            <a href='https://www.imdb.com/name/nm6716327' target='_black'>
            <img src={IMDP_Img} alt='Kartik Gurunathan'/>
            </a>
        </div>
    </div>
  )
}

export default IMDP