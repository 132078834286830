import React from 'react'
import '../../css/Contact_heading.css'

const Contact_heading = () => {
  return (
    <div className='contact-heading-container'>
      <div className='contact-heading'>
        <div className='head'>
          <h1>Let's Create Something Amazing Together <span>Ready to Push the Boundaries?</span></h1>
          <p>Whether <span>you're dreaming up the next blockbuster sequence</span> or pioneering new grounds in interactive experiences, we're here to turn your vision into reality. Our team in <span>Pondicherry is ready to take on your biggest VFX challenges.</span></p>
        </div>
      </div>
    </div>
  )
}

export default Contact_heading