import React from 'react'
import '../../css/Areas_Of_Expertise.css'

const Areas_Of_Expertise = () => {
  return (
    <div className='Areas-Of-Expertise-container'>
        <div className='Areas-Of-Expertise'>
            <div className='head'>
                <h1>Areas of Expertise</h1>
            </div>
            <div className='content' id='Film_Television'>
                <h2>1) Film & Television</h2>
                <ul>
                    <li>Where <strong>reality meets imagination</strong>. Our effects don't just fill the frame – <strong>they tell stories.</strong></li>
                    <li>Ocean dynamics that capture the raw <strong>power of nature.</strong></li>
                    <li><strong>Atmospheric effects </strong>that set the perfect mood.</li>
                    <li>Destruction sequences that <strong>respect physics while delivering spectacle.</strong></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Areas_Of_Expertise