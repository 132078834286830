import React from 'react'
import img from '../../assets/internship/internship-certificate.png'
import '../../css/internshiplanding.css'
import { useNavigate } from 'react-router-dom';

const Internshiplanding = () => {
    const navigate = useNavigate();
  return (
   <>
<div className='internship-landing-container'>

<div className='internship-landing'>
<div className='internship-certificate'>
    <div className='certificate-image'>
        <img
        src={img}
        alt='internship-certificate-pixlvfx'
        />
    </div>
</div>

<div className='internship-landing-right'>
    <div className='right-first'>
        <h1 id='head'>
            Unpaid Internship
        </h1>
        <h1>
       <span> Opportunity at PIXL VFX</span>
        </h1>
        <p>
        Are you looking to build a high-quality showreel, gain industry exposure, and upskill yourself in VFX?  
        </p>
        <p>
        PIXL VFX, under the guidance of FX Supervisor Kartik Gurunathan, is offering
        </p>
        <p>Send your portfolio and resume to <span> <strong>internship@pixlvfx.com</strong> </span>  and take the next step in your career with us!</p>
    </div>
<div className='right-second'>
    <h3>
    2-month unpaid internship
    </h3>
    <div className='right-second-boxes'>
        <div className='box1'>
           <p> Certificate </p>
        </div>
        <div className='box2'>
           <p> Portfolio Developement</p>
        </div>
    </div>
    <div className='right-second-boxes'>
        <div className='box1'>
           <p>  Industry Exposure </p>
        </div>
        <div className='box2'>
           <p>Hands-On Learning</p> 
        </div>
    </div>
<div className='right-second-button'>
<button onClick={() => navigate('/intership/intership-register')}>Register Now</button>
</div>

</div>


</div>

<div className='right-second-button1'>
<button onClick={() => navigate('/intership/intership-register')}>Register Now</button>
</div>
<div className='right-second-mobile'>

        <div className='box2'>
           <p> Certificate </p>
        </div>
        <div className='box2'>
           <p> Portfolio Developement</p>
        </div>
        <div className='box2'>
           <p>  Industry Exposure </p>
        </div>
        <div className='box2'>
           <p>Hands-On Learning</p> 
        </div>
    
   

</div>

</div>
</div>



   </>
  )
}

export default Internshiplanding