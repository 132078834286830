import React, { useState, useEffect } from 'react';
import '../../css/charitylast.css';
import charityimage1 from '../../assets/charity/charity_2.png';
import charityimage2 from '../../assets/charity/charity_3.png';
import charityimage3 from '../../assets/charity/charity_2.png';

function Charitylast() {
  const images = [charityimage1, charityimage2, charityimage3];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length]);

  return (
    <div className="charity-last-container">
      <div className="charity-last">
        {/* Left Section with Text */}
        <div className="charity-last-left">
          <h1>PIXL Visual Effects</h1>
          <p>Empowering Underprivileged Children Through Sports</p>

          <p>
            At PIXL Visual Effects, we believe in giving back to the community.
            That's why we <span>dedicate 10%</span> of our profits to charitable
            initiatives focused on supporting underprivileged children.
          </p>

          <p>
            One of our key efforts is promoting sports, <span>particularly
            football</span>, as a way to inspire and empower young minds. We
            organize football tournaments and provide essential sports equipment
            to <span>children who lack access to such opportunities.</span>
          </p>

          <p>
            Through these initiatives, PIXL aims to nurture talent, encourage
            teamwork, and instill confidence in the next generation, ensuring
            they have the tools they need to succeed both on and off the field.
          </p>
          <p>
            Join us in making a difference—<span>one game at a time.</span>
          </p>
        </div>

        {/* Right Section with Auto-Slider */}
        <div className="charity-last-right">
          <div className="charity-last-image">
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Charitylast;
