import React from 'react'
import "../../css/Where_Pixl_Visualeffect.css";

const Where_Pixl_Visualeffect = () => {
  return (
    <div className='Where-Pixl-Visualeffect-container'>
        <div className='Where-Pixl-Visualeffect'>
            <div className='head'>
                <h1>This Is Where PIXL VISUAL EFFECTS Comes In</h1>
            </div>
        <div className='text'>
            <p>We’re not just another <strong>VFX studio.</strong> We’re the team you call when:</p>
            <p>Your current visual effects aren’t cutting it.</p>
            <p>The big climactic sequence needs that <strong>extra "wow" factor.</strong></p>
            <p>You need complex <strong>Houdini-powered simulations</strong> that actually finish rendering on time.</p>
            <p>Your pipeline is a nightmare, and you need a <strong>solution that delivers real-time VFX.</strong></p>
        </div>
        </div>
        <div className='top'>
    <h1>Then it’s time to talk with us.</h1>
</div>
    </div>
  )
}

export default Where_Pixl_Visualeffect