
import React from 'react'
import showreelvideo from '../../assets/showreel/showreel-bg.mp4'
import '../../css/Showreellanding.css'

const Slider_Showreel = () => {
  return (
 <>
 <div className="showreel-video-container">
      <video autoPlay muted loop id="background-video">
        <source src={showreelvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h1>Experience Visual Excellence</h1>
        <p> Dive into a world where ocean waves crash with perfect precision and fire breathes with life. Browse through <span> <strong>award-worthy VFX moments created for global entertainment giants.</strong></span></p>
       
      </div>
    </div>

 
 </>
  )
}

export default Slider_Showreel

