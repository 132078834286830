import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import "../css/Gaming.css";
import Gaming_video from "../Componets/gaming/Gaming_video";
import Nav from "../Componets/Nav";
import Footer from "../Componets/Footer";
import GamingOurVideo from "../Componets/gaming/GamingOurVideo";
import Farmer_Game from "../Componets/gaming/Farmer_Game";
import Karala from "../Componets/gaming/Karala";

const Gaming = () => {
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState("");

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription(
      "Kartik G - Visual Effects Lead with 15+ years of experience in FX, Liquid Simulations, and Pyro Techniques. Creator of stunning effects for Netflix, HBO, and major films. Discover the portfolio of an industry-leading VFX artist."
    );
  }, []); // This effect ru
  return (
    <>
      {/* ---------------- SEO ------------------ */}
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
      <Nav />
      <Gaming_video />
      <GamingOurVideo />
      <Farmer_Game />
      <Karala />
      <Footer />
    </>
  );
};

export default Gaming;
