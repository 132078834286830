import React from 'react'
import "../../css/techinnovation.css"
import techimage from "../../assets/innovation/techinnovation.png"

function TechInnovation() {
  return (
  <>
  <div className='tech-innovation-container'>
    <div className='tech-innovartion'>
        <h1>
        Our Technological Innovations
        </h1>

        <div className='tech-image'>
            <img 
            src={techimage}
            alt='technologies'/>
        </div>
    </div>
  </div>
  </>
  )
}

export default TechInnovation