import React from 'react'
import "../../css/Impossible_Possible.css";

const Impossible_Possible = () => {
  return (
    <div className='Impossible-Possible-container'>
        <div className='Impossible-Possible'>
            <h1>Let’s Make the Impossible Possible</h1>
            <p>Drop us a line. Let’s talk about making digital effects that captivate, inspire, and leave a lasting impact. Reach out today, and let’s start crafting movie magic together.</p>
        </div>
    </div>
  )
}

export default Impossible_Possible