import React, { useEffect, useState } from 'react'
import Nav from "../Componets/Nav";
import Footer from "../Componets/Footer";
import { Helmet } from "react-helmet";
import Journey_of_karthik from '../Componets/About_founder/Journey_of_karthik';
import Industry_Impact from '../Componets/About_founder/Industry_Impact';
import Technical_Innovation from '../Componets/About_founder/Technical_Innovation';
import Notable_Achievements from '../Componets/About_founder/Notable_Achievements';
import Leadership_Philosophy from '../Componets/About_founder/Leadership_Philosophy';
import Looking_Forward from '../Componets/About_founder/Looking_Forward';
import Connect_Collaborate from '../Componets/About_founder/Connect_Collaborate';
import IMDP from '../Componets/About_founder/IMDP';
import Polioflio from '../Componets/About_founder/Polioflio';
import Journey_of_Raghunathan from '../Componets/About_founder/Journey_of_Raghunathan';

const About_Founder = () => {
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState("");

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription(
      "Kartik G - Visual Effects Lead with 15+ years of experience in FX, Liquid Simulations, and Pyro Techniques. Creator of stunning effects for Netflix, HBO, and major films. Discover the portfolio of an industry-leading VFX artist."
    );
  }, []); // This effect ru
  return (
    <>
      {/* ---------------- SEO ------------------ */}
      <Helmet>
        
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ---------------SEO END---------------- */}
      <Nav />
      <Journey_of_karthik/>
      <Journey_of_Raghunathan/>
      <Industry_Impact/>
      <Technical_Innovation/>
      <Notable_Achievements/>
      <Leadership_Philosophy/>
      <Looking_Forward/>
      <Connect_Collaborate/>
      <Polioflio/>
      <IMDP/>
      <Footer />
    </>
  );
};

export default About_Founder;
