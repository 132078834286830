// src/WorkInProgress.js
import React from 'react';
import '../css/WorkInProgress.css'; // We'll add the CSS file here

const WorkInProgress = () => {
  return (
    <div className="work-in-progress">
      <div className="content">
        <h1>Work In Progress</h1>
        <p>Thanks for your patience!</p>
        <div className="button-container">
          <button className="back-button" onClick={() => window.history.back()}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkInProgress;