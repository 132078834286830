import React from "react";
import "../../css/GamingOurVideo.css";
import Play_Btn from "../../assets/icon/play_btn.png";

// Videos
import gaming_1 from "../../assets/showreel/showreel-video.mp4";

const ShowreelVideo = () => {
  const handleVideoClick = (e) => {
    const videoElement = e.currentTarget.querySelector("video");
    const controlElement = e.currentTarget.querySelector(".controll");

    if (videoElement) {
      if (videoElement.paused) {
        videoElement.setAttribute("controls", "true"); // Add the controls attribute
        videoElement.play(); // Play the video
        if (controlElement) {
          controlElement.style.display = "none"; // Hide the play button
        }
      } else {
        videoElement.removeAttribute("controls"); // Remove the controls attribute
        videoElement.pause(); // Pause the video
        if (controlElement) {
          controlElement.style.display = "flex"; // Show the play button
        }
      }
    }
  };

  return (
    <div className="GamingOurVideo-container">
      <div className="GamingOurVideo">
        <div className="video-container">
          {/* Loop through video sources and display each video */}
          {[gaming_1].map((videoSrc, index) => (
            <div
              key={index}
              className="video-item"
              onClick={handleVideoClick}
            >
              <video>
                <source src={videoSrc} />
              </video>
              <div className="controll">
                <img src={Play_Btn} alt="Play" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowreelVideo;
