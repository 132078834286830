import React from 'react'
import '../../css/What_Sets_Us_Apart.css'

const What_Sets_Us_Apart = () => {
  return (
    <div className='What_Sets_Us_Apart_container'>
        <div className='What_Sets_Us_Apart'>
            <div className='head'>
            <h1>What Sets Us Apart</h1>
            </div>
        <div className='content'>
        <h2>Deep Technical DNA</h2>
        <p>Sure, anyone can run a simulation. But can they make water move like poetry? Can they make fire tell a story? We don't just operate Houdini – we push it to places its creators didn't know it could go.</p>
        </div>
        <div className='content'>
        <h2>Obsession with Reality</h2>
        <p>Before we touch a keyboard, we study reality. <strong> Want to create the perfect storm?</strong> We've analyzed hundreds of hours of ocean footage. Need believable destruction? We've consulted structural engineers. This obsession with <strong>real-world physics shows in every frame we deliver.</strong></p>
        </div>
          
        </div>
    </div>
  )
}

export default What_Sets_Us_Apart