import React from 'react'
import '../../css/termsandcondition.css'

function Termsandcondition() {
  return (
<div className='terms-policy-container'>
    <div className='terms-policy'>
        <div className='intro'>
            <h1>
            Terms and Conditions for Pixl VFX
            </h1>
            <h2>
            Introduction  
            </h2>
            <p>
            These Terms and Conditions govern your use of<span> Pixl VFX's</span> website and services. By accessing or using our website or services, you agree to these terms. If you do not agree to these terms, you should not use our website or services.
            </p>
        </div>
        <div className='list-content'>
            <h2>
            1. Services Provided
            </h2>
            <p>
           <span> Pixl VFX</span> offers visual effects (VFX) services, including but not limited to:
            </p>

            <ul>
                <li>
                Motion graphics
                </li>
                <li>
                3D modeling and animation
                </li>
                <li>
                Post-production services
                </li>
                <li>
                Visual effects for film, television, advertising, and digital media
                </li>
            </ul>
        </div>



        <div className='list-content'>
            <h2>
            2. Use of Our Website and Services 
            </h2>
            <ul>
                <li>
                2. Use of Our Website and Services
                </li>
                <li>
                You agree to use our website and services in accordance with applicable laws and regulations.
                </li>
                <li>
                You must not use our website for any unlawful or harmful purposes, including spamming, distributing malware, or violating intellectual property rights.
                </li>
            </ul>
        </div>


        <div className='list-content'>
            <h2>
            3. Intellectual Property
            </h2>
            <ul>
                <li>
                All content on our website, including text, images, videos, logos, and designs, is the property of Pixl VFX or our licensors and is protected by copyright, trademark, and other intellectual property laws.
                </li>
                <li>
                You may not reproduce, distribute, or create derivative works based on our content without prior written permission. 
                </li>
            </ul>
        </div>


        <div className='list-content'>
            <h2>
            4. Project Deliverables
            </h2>
            <ul>
                <li>
                We will provide VFX services as per the agreement or contract with you.
                </li>
                <li>
                Final deliverables will be provided upon project completion, subject to payment terms and the agreement made prior to the start of the project.
                </li>
                <li>
                We retain the right to showcase completed projects as part of our portfolio unless otherwise agreed upon.
                </li>
            </ul>
        </div>


        <div className='list-content'>
            <h2>
            5. Payment Terms
            </h2>
            <ul>
                <li>
                Payments for services must be made according to the terms outlined in the contract or agreement.
                </li>
                <li>
                All payments are due as specified in the contract. If you fail to make a payment, we reserve the right to suspend or terminate services.
                </li>
            </ul>
        </div>

        <div className='list-content'>
            <h2>
            6. Confidentiality
            </h2>
            <p>
            We understand the importance of confidentiality and will keep all project-related information confidential unless we are required by law to disclose it.
            </p>
        </div>


        <div className='list-content'>
            <h2>
            7. Limitation of Liability
            </h2>
            <ul>
                <li>
                To the fullest extent permitted by law, Pixl VFX will not be held liable for any damages arising out of or related to the use of our website or services, including direct, indirect, incidental, or consequential damages.
                </li>
                <li>
                We do not guarantee that our services will meet your specific requirements or that the results will be error-free.
                </li>

            </ul>
        </div>



        <div className='list-content'>
            <h2>
            8. Indemnification
            </h2>
            <p>
            You agree to indemnify and hold harmless Pixl VFX, its employees, and contractors from any claims, losses, or damages arising from your use of our website or services or your violation of these Terms and Conditions.
            </p>
        </div>


        <div className='list-content'>
            <h2>
            9. Termination
            </h2>
            <p>
            We reserve the right to suspend or terminate your access to our website or services at any time without notice if you violate these terms or if we believe it is necessary.
            </p>
        </div>


        <div className='list-content'>
            <h2>
            10. Governing Law
            </h2>
            <p>
            These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes will be resolved in the appropriate courts of india.
            </p>
        </div>


        <div className='list-content'>
            <h2>
            11. Amendments
            </h2>
            <p>
            We may update or modify these Terms and Conditions at any time. Any changes will be posted on this page, and the effective date will be updated accordingly.
            </p>
        </div>
<div className='list-content'>
    <h2>
        12.Showreel Usage

    </h2>
    <ul>
        <li>
            
        Pixl VFX reserves the right to showcase completed projects in our Showreel page after the completion of the VFX work.

        </li>
        <li>
        By availing our services, you agree that any work completed by Pixl VFX for you may be displayed on our website or other promotional materials as part of our portfolio, unless otherwise agreed upon in writing prior to the project's completion.
        </li>
    </ul>
</div>

        <div className='list-content'>
    <h2>
    13. Contact Us
    </h2>
<p>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
</p>
    <ul>
        <li>
       <span> Email: </span> business@pixlvfx.com
        </li>
        <li>
        <span>Phone:</span> +91  413 296 1868
        </li>
        <li>
        <span>Address:</span>
        PIXL VISUAL EFFECTS, <br />
No. 30, Second Floor, <br />
Subbaiah Salai, <br />
Pondicherry 605002, <br />
India.

        </li>
    </ul>
</div>
        
    </div>
</div>
  )
}

export default Termsandcondition