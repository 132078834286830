import React from "react";
import "../../css/Leadership_Philosophy.css";
const Leadership_Philosophy = () => {
  return (
    <div className="Leadership-Philosophy-container">
      <div className="Leadership-Philosophy">
        <div className="left">
          <div className="content">
            <p>
              Kartik’s leadership style is centered on collaboration and
              continuous learning. He believes in nurturing talent and
              empowering his team to push creative limits. Under his guidance,
              PIXL VISUAL EFFECTS has become synonymous with innovation,
              delivering exceptional results that exceed client expectations.
              His vision for the company revolves around fostering a culture of
              creativity, technical mastery, and a shared passion for visual
              storytelling.
            </p>
          </div>
        </div>
        <div className="right">
          <h1>Leadership Philosophy</h1>
          <h2>Driving Excellence Through Collaboration</h2>
        </div>
      </div>
    </div>
  );
};

export default Leadership_Philosophy;
