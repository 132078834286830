import React, {  useState } from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import footerLogo from "../assets/logo/logo.png";
import footerInstagram from "../assets/icon/instagram-light.png";
import footerFacebook from "../assets/icon/facebook_light.png";
import footerLinkedIn from "../assets/icon/linkedin-light.png";
import footerYoutube from "../assets/icon/youtube-light.png";
import footerWhatsapp from "../assets/icon/whatsapp-light.png";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});

  // useEffect(() => {
  //     const handleResize = () => {
  //         setIsMobile(window.innerWidth <= 767);
  //     };

  //     handleResize();
  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []);

  const toggleDropdown = (menu) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  // Define menu data with paths
  const menuData = [
    {
      title: "Careers",
      items: [
        { name: 'Job Openings', path: '/jobs' },
        { name: 'Internships', path: '/internship' },
        { name: 'Training', path: '/training' },
      
      ],
    },
    {
      title: "Company Information",
      items: [
        { name: 'Home', path: '/' },
        { name: 'About Us', path: '/about-us' },
        { name: 'About Founder', path: '/about-founder' },
      
        { name: 'Showreel', path: '/show-reel' },
        { name: 'Gaming', path: '/gaming' },
        { name: 'Charity', path: '/charity' },
      ],
    },
   
    {
      title: "Products",
      items: [      
        { name: "Yalifx", path: "https://yalifx.com/" },        
        { name: "Innovations", path: "/innovations" },        
      ],
    },
    {
      title: "Legal & Policies",
      items: [
        { name: 'Privacy  Policy', path: '/privacy-and-policy' },
        { name: 'Terms of Service', path: '/terms-and-conditions' },
      ],
    },
    {
      title: "SUPPORT",
      items: [
        { name: "Contact Us", path: "/contact-us" },
       

      ],
    },
  ];

  return (
    <footer>
      <div className="footer-container">
        <div className="left">
          <div className="logo">
            <img src={footerLogo} alt="logo" />
          </div>
          <div className="social-media">
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
              <div className="link">
                <img src={footerInstagram} alt="instagram" />
              </div>
            </a> */}
            <a href="https://www.youtube.com/@pixlvisualeffects8385" target="_blank" rel="noopener noreferrer">
              <div className="link">
                <img src={footerYoutube} alt="youtube" />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/pixl-vfx/" target="_blank" rel="noopener noreferrer">
              <div className="link">
                <img src={footerLinkedIn} alt="linkedin" />
              </div>
            </a>
            <a href=" https://wa.me/+919655407770" target="_blank" rel="noopener noreferrer">
              <div className="link">
                <img src={footerWhatsapp} alt="whatsapp" />
              </div>
            </a>
            <a href="https://www.facebook.com/share/14X9zZ3iid/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <div className="link">
                <img src={footerFacebook} alt="facebook" />
              </div>
            </a>
          </div>
          <div className="text">
            {/* <p>Get exclusive assets sent straight to your inbox</p> */}
          </div>
        </div>
        <div className="right">
          {menuData.map((menu) => (
            <div className="menu-links" key={menu.title}>
              <ul>
                <li className="head" onClick={() => toggleDropdown(menu.title)}>
                  {menu.title}{" "}
                  {isMobile &&
                    {
                      /*  <img src={menuDownIcon} alt='' />*/
                    }}
                </li>
                <div
                  className={`footer-dropdown ${
                    dropdownOpen[menu.title] ? "footer-dropdown-active" : ""
                  }`}
                >
                  {menu.items.map((item, index) => (
                    <li key={index}>
                      {item.name=='Yalifx'?
                      <Link to={item.path} target="_black" >{item.name}</Link>:
                      <Link to={item.path}>{item.name}</Link>
                      }
                     </li>
                  ))}
                </div>
              </ul>
            </div>
          ))}
          {/* <div className="google_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1951.8569783702308!2d79.82931762844066!3d11.924983938626104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5361876b8f79bf%3A0x45821583613f78cd!2sPIXL%20VFX!5e0!3m2!1sen!2sin!4v1731757779630!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div> */}
        </div>
      </div>
      <div className="line"></div>
      <div className="copyright">
        <p>© 2025 Pixl Visual Effects. All Rights Reserved. | Designed and Developed by Cuckoo Images</p>
       
      </div>
    </footer>
  );
};

export default Footer;
