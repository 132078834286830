import React from 'react'
import "../../css/Talk_With_Us.css";

const Talk_With_Us = () => {
  return (
    <div className='Talk-Us-container'>
        <div className='Talk-Us'>
        <div className='bottom'>
                <h1>But Don’t Just Take Our Word for It...</h1>
                <p>"PIXL saved our third act. Their water simulation work transformed what could have been a basic chase scene into a showstopper that audiences still rave about."</p>
            </div>
            
            <div className='center'>
                <div className='head'>
                    <h1>The PIXL Difference</h1>
                </div>
                <div className='text'>
                    <p>At PIXL VISUAL EFFECTS, we utilize an advanced Houdini-powered pipeline to deliver:</p>
                    <p>Fluid simulations that behave like <strong>real water, simulated with unparalleled realism.</strong></p>
                    <p><strong>Fire and smoke effects</strong> that interact naturally with their environment, enhancing <strong>motion graphics.</strong></p>
                    <p>Destruction sequences that obey <strong>real physics,</strong> elevating the impact of your scenes.</p>
                    <p><strong>Custom FX animations</strong> that leave audiences asking, <strong>"How did they DO that?".</strong></p>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Talk_With_Us