import React from "react";
import '../../css/Technical_Innovation.css'

const Technical_Innovation = () => {
  return (
    <div className="Technical-Innovation-container">
      <div className="Technical-Innovation">
        <div className="left">
          <div className="head">
            <h1>Technical Innovation</h1>
            <h2>Pushing Boundaries with Precision</h2>
          </div>
        </div>
        <div className="right">
          <div className="content">
            <p>
              At the heart of Kartik’s success is his commitment to technical
              innovation. He consistently pushes the boundaries of FX
              simulations, exploring new techniques and developing unique
              solutions that elevate the quality of visual effects. Whether it’s
              creating hyper-realistic water splashes or simulating dynamic fire
              and smoke, Kartik’s expertise ensures that each frame delivers an
              unparalleled level of realism and detail.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technical_Innovation;
