import React from 'react'
import "../../css/Merging_Creativity.css";

const Merging_Creativity = () => {
  return (
    <div className='Merging-Creativity-container'>
        <div className='Merging-Creativity'>
            <div className='top'>
                <h2>Innovations at PIXL Visual Effect</h2>
                <h1>Merging Creativity</h1>
            </div>
            <div className='center'>
                <p>At PIXL Visual Effects, innovation drives everything we do. Beyond our expertise in high-end VFX, we are at the forefront of pioneering<strong> AR, VR, and game technology solutions</strong> that redefine storytelling and immersive experiences. Our focus extends to pushing boundaries in creative technology and developing smart, future-forward solutions for global challenges.</p>
            </div>
            <div className='bottom'>
                <h1>with Technology</h1>
            </div>
        </div>
    </div>
  )
}

export default Merging_Creativity