import React from 'react'
import "../../css/Polioflio.css";

const Polioflio = () => {
  return (
    <div className='Polioflio-container'>
        <div className='Polioflio'>
        <p>Leading VFX innovation with over 15 years of award-winning visual effects experience. <a href="https://kartikfx.wordpress.com/" target='_black'>View the portfolio</a>.</p>
        </div>
    </div>
  )
}

export default Polioflio