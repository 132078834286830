import React, { useState, useEffect } from "react";
import "../../css/Slider.css";
import { Link } from "react-router-dom";
import Bg_Video_Low from '../../assets/pixl_videos/pixl_slider_low.mp4'; // low quality
import Bg_Video_Medium from '../../assets/pixl_videos/pixl_slider_medium.mp4'; // medium quality
import Bg_Video_High from '../../assets/pixl_videos/pixl_slider_high.mp4'; // high quality
import ImageComparisonWithSlider from "../ImageComparisonWithSlider";

const Slider = () => {
  const [videoSrc, setVideoSrc] = useState(Bg_Video_Low); // Default to low quality

  useEffect(() => {
    // Determine the video quality based on window width
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setVideoSrc(Bg_Video_High); // High quality for large screens
      } else if (window.innerWidth >= 768) {
        setVideoSrc(Bg_Video_Medium); // Medium quality for tablets/desktops
      } else {
        setVideoSrc(Bg_Video_Low); // Low quality for mobile
      }
    };

    // Run the resize handler initially and whenever the window is resized
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run the effect only once when component mounts

  return (
    <div className="Slider-container">
      <div className="slider_bg_video">
        <video muted loop autoPlay>
          <source
            src={videoSrc}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="Slider">
        <div className="left">
          <div className="head">
            <h3>Welcome to</h3>
            <h1>PIXL VISUAL EFFECTS</h1>
          </div>
          <div className="content">
            <p>
              At PIXL VISUAL EFFECTS, we specialize in delivering{" "}
              <span>high-end FX simulations</span> for the{" "}
              <span>film, gaming, and immersive experience</span> industries.
              Using the latest technologies like Houdini, we bring complex
              water, fire, smoke, and other physics-based effects to life,
              making every frame breathtakingly realistic. Whether you're
              producing a{" "}
              <span>
                blockbuster movie, creating an immersive VR experience,
              </span>{" "}
              or developing the next hit game, our expertise ensures that your{" "}
              <span>vision becomes a reality.</span>
            </p>
          </div>
          <div className="btn-container">
           <Link to={'/show-reel'}><div className="btn">Show Reel</div></Link> 
          <Link to={'/innavation'}>  <div className="btn">Innovations</div></Link>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default Slider;
