import React from 'react'
import "../../css/What_If_you_could.css";

const What_If_you_could = () => {
  return (
    
    <div className='What-If-you-could-container'>
      <div className='dummy'>
        
      </div>
        <div className='What-If-you-could'>
            <div className='head'>
                <h1>What If You Could...</h1>
            </div>
            <div className='home-content'>
                <p>Present renders that make directors lean forward in their seats.</p>
                <p><strong> Deliver water simulations so realistic,</strong> viewers reach out to touch the screen.</p>
                <p>Create fluid dynamics that hold up even on an <strong>IMAX screen.</strong></p>
                <p>Actually <strong>finish your shots ahead of deadline,</strong> without sacrificing quality.</p>
            </div>
        </div>
    </div>
    
  )
}

export default What_If_you_could