import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import '../css/Error.css';  // Import the CSS for animation and responsiveness

const Error = () => {
  const navigate = useNavigate();  // Initialize navigate function

  // Function to navigate to home page
  const goHome = () => {
    navigate('/');  // Navigate to the home route (or change this to any route)
  };

  return (
    <div className="error-container">
      <div className="error-message">
        <h1 className="error-text">404</h1>
        <h2 className="error-text">Page Not Found</h2>
        <p className="error-description">
          Oops! The page you are looking for doesn't exist.
        </p>
        <div className="animation-container">
          <div className="glitch-animation"></div>
        </div>
        {/* Back to Home button */}
        <button className="back-btn" onClick={goHome}>
          Back to Home
        </button>
      </div>
    </div>
  );
}

export default Error;
