import React from 'react'
import '../../css/Beyond_The_Expected.css'
import Vr_Img from '../../assets/about_us/VFX_for_virtual_reality.png'

const Beyond_The_Expected = () => {
  return (
    <div className='Beyond-The-Expected-container'>
           <div className='Beyond-The-Expected'>
        <div className='left'>
            <div className='img'>
                <img src={Vr_Img} alt='VR-optimized particle effects studio'/>
            </div>
        </div>
        <div className='right'>
        <div className='head'>
            <h1>Beyond the Expected</h1>
           </div>
        <div className='content'>
            <ul>
                <li>When others say "that's impossible," we start taking notes. Our team thrives on challenges that make other studios nervous</li>
                <li>Million-particle fluid sims that actually render</li>
                <li>Fire dynamics that interact believably with environments</li>
                <li>Destruction sequences that consider material stress patterns</li>
                <li><strong>Real-time effects</strong> that hold up in VR</li>
            </ul>
        </div>
        </div>
           </div>
    </div>
  )
}

export default Beyond_The_Expected