import React from "react";
import "../../css/Journey_of_karthik.css";
import author_Img from '../../assets/logo/pixl_author.png'
 
const Journey_of_karthik = () => {
  return (
    <div className="Journey-of-karthik-container">
      <div className="Journey-of-karthik">
        <div className="left">
          <div className="head">
            <h1>The Journey of Kartik G</h1>
            <h2>A Visionary FX Supervisor</h2>
          </div>
          <div className="content">
            <p>
              Kartik G’s story Is one of passion, innovation, and relentless
              pursuit of excellence. With over 15 years in the VFX and gaming
              industries, his journey started with a fascination for visual
              storytelling that quickly evolved into a career marked by
              groundbreaking work and creative leadership. Kartik’s deep- rooted
              passion for FX simulations has been the driving force behind his
              impressive portfolio of projects, from blockbuster films to
              immersive gaming experiences.
            </p>
          </div>
        </div>
        <div className="right">
            <div className="img">
                <img src={author_Img} alt="pixlvfx_author" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Journey_of_karthik;
