import React from 'react';
import '../../css/whahappen.css';
import vector from '../../assets/production_Training/Vector.png'

function Whathappen() {
  return (
    <>
    <div className='whathappen-container'>
      <h1>
      What Happens Next?
      </h1>
      <div className='whathappen'>
        <div className='whathappen-black'>
        After submitting your inquiry, our team will
        </div>

        <div className='whathappen-list'>
        
          <p> <img src={vector} alt='vector' /> &nbsp; Provide detailed course information.</p>
          <p>
          <img src={vector} alt='vector' /> &nbsp;  Share the pricing breakdown and payment options
          </p>
          <p>
          <img src={vector} alt='vector' /> &nbsp; Guide you through the enrollment process.
          </p>
          
        </div>
      </div>
    </div>
    </>
  );
}

export default Whathappen;
