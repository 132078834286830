import React from 'react'
import certificate from '../../assets/internship/internship-certificate.png'
import '../../css/internshiplastcertificate.css'


function Internshiplastcertificate() {
  return (
    <>
    <div className='internshiplastcertificate-container'>
<div className='internshiplast-certificate'>
    <div className='last-left-certficate'>
        <div className='last-certificate-image'>
            <img 
            src={certificate}
            alt=''
            />
        </div>
    </div>
    <div className='last-right-text'>
        <h1>
        What You’ll Gain
        </h1>
        <p>
      <span>Certificate: </span> Earn a PIXL-certified internship certificat </p>
        <p>
        <span> Industry Exposure:</span> Connect with professionals and understand high-quality production workflows.
        </p>
        <p>
      <span>  Portfolio Development:</span> Build a top-tier showreel with professional guidance.
        </p>
        <p> 
        <span>Hands-On Learning: </span> Experiment with Houdini and Unreal in a collaborative environment.
        </p>
    </div>
</div>
<div className='last-lets'>
  <h1>
  Let’s create something extraordinary together!
  </h1>
</div>
    </div>
    </>
  )
}

export default Internshiplastcertificate