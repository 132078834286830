import React from 'react';
import '../../css/course.css';
import img1 from '../../assets/production_Training/card_img_1.png';
import img2 from '../../assets/production_Training/card_img_2.png';
import img3 from '../../assets/production_Training/card_img_3.png';

const Courses = () => {
  const courses = [
    { image: img1 },
    { image: img2 },
    { image: img3 },
  ];

  return (
    <div className="courses-container">
      <h2>Our Courses Include!</h2>
      <div className="courses-grid">
        {courses.map((course, index) => (
          <div className="course-card" key={index}>
            <img src={course.image} alt={`Course ${index + 1}`} className="course-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
