import React from 'react'
import '../../css/Industry_Impact.css'
const Industry_Impact = () => {
  return (
    <div className='Industry-Impact-container'>
        <div className='Industry-Impact'>
            <div className='left'>
                <div className='head'>
                    <h1>Industry Impact </h1>
                    <h2>Shaping the VFX Landscape</h2>
                </div>
                <div className='content'>
                    <p>Kartik has been a key player in redefining visual effects in India and beyond. By leveraging advanced tools like Houdini, Kartik’s
                    work has brought life-like effects to screens worldwide, earning him a reputation for creating visually
 stunning and emotionally compelling experiences.</p>
                </div>
            </div>
            <div className='right'>
                <p>He has set new benchmarks in the industry.</p>
                <ul>
                    <li>Mastered complex simulations</li>
                    <li>Specializes in intricate water dynamics</li>
                    <li>Expert in creating the mesmerizing dance of 
fire and smoke</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Industry_Impact
