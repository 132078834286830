import React from 'react'
import '../../css/Our_goal.css'


const Our_goal = () => {
  return (
    <div className='our-goal-container'>
        <div className='our-goal'>
            <div className='left'></div>
            <div className='right'>
                <div className='head'>
                    <h1>Our Goal?</h1>
                </div>
                <div className='content'>
                    <p>
                    To bridge the gap between <strong>traditional </strong> and the evolving needs of the <strong>VFX industry, offering affordable, high-quality training</strong> that <strong>prepares students for real-world demands. </strong>
                    </p>
                    <p>Each program is designed and supervised by <strong>top professionals</strong> and <strong>vetted by me to ensure the highest standards</strong> and relevance.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Our_goal